import React, { Component } from 'react'

class Empty extends Component {
  render() {
    return (
      <div>
        <h1>Uplne prazdna stranka</h1>
        <p>Na tjeto stranke nieje nic ... any layout</p>
      </div>
    )
  }
}

export default Empty